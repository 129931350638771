import { mapActions, mapGetters, mapMutations } from 'vuex'

import validationMixin from '@/mixins/validation'

import SeoForm from '@/components/seo-form/index.vue'

export default {
  name: 'events-seo',
  mixins: [validationMixin],
  data () {
    return {
      seoContent: '',
      id: this.$route.params.id
    }
  },
  components: {
    SeoForm
  },
  computed: {
    ...mapGetters({
      seo: 'eventsSeo/seo',
      seoLoading: 'eventsSeo/seoLoading',
      updateSeoLoading: 'eventsSeo/seoUpdateLoading',
      currentItem: 'eventsGeneral/currentItem',
      accessCountries: 'profile/accessCountries',
      setting: 'courses/setting'
    })
  },
  created () {
    if (!this.setting) {
      this.fetchSetting()
    }
    if (!this.currentItem) {
      this.fetchData({ id: this.$route.params.id })
    }
    if (!this.seo) {
      this.fetchSeo({ id: this.$route.params.id }).then(response => {
        this.seoContent = response
        this.setCountryIds([response.country_id])
      })
    }
  },
  methods: {
    ...mapActions({
      fetchData: 'eventsGeneral/GENERAL_GET_DATA',
      fetchSetting: 'courses/COURSES_GET_SETTING',
      fetchSeo: 'eventsSeo/SEO_GET_DATA',
      update: 'eventsSeo/UPDATE',
      create: 'eventsSeo/CREATE'
    }),
    ...mapMutations({
      setCountryIds: 'profile/SET_COUNTRY_IDS',
      setSeo: 'eventsSeo/SEO_SET_DATA'
    }),
    submit (formData) {
      const item = this.seo.find(e => e.locale === formData.locale)
      if (!item) {
        formData.event_id = this.$route.params.id
        this.create(formData).then(() => {
          this.$toasted.success(this.$t('success_created'))
        })
      } else {
        formData._method = 'patch'
        this.update(formData).then(() => {
          this.$toasted.success(this.$t('success_updated'))
        })
      }
    }
  },
  destroyed () {
    this.setSeo(null)
  }
}
